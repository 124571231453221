import { MouseEvent, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconChevronDown } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';

import { RequestQuoteOutlined, Send } from '@mui/icons-material';
import CircleIcon from '@mui/icons-material/Circle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  List,
  ListItemButton,
  Popover,
  Skeleton,
  SvgIcon,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';

import {
  PayrollPeriodList,
  PayrollPeriodSummary,
  PayrollTypes,
  fetchSearchAllPeriodsSummaries,
} from '@octopus/api';
import {
  formatInteger,
  formatMoney,
  formatPeriodDate,
} from '@octopus/formatters';
import { payrollStatusIndex, payslipStatuses } from '@octopus/payroll-types';
import {
  DataGrid,
  DataGridToolbar,
  FilterOptions,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
  makeMoneyRangeFilter,
  makeYearMonthPickerFilter,
  useDataGrid,
} from '@octopus/ui/data-grid';

import { TrashIcon } from '../../modules/components/autonomos/icon';
import { DataFetching, FetchResult } from '../../modules/dataFetching';
import { FLAGS } from '../../modules/flags';
import { getActiveElementFilters, getActiveRangeFilters } from '../../utils';
import {
  countPayrollStatus,
  hasGeneratedAllPayslips,
} from '../../utils/statusIndexUtils';

import { useSendPayslips } from './SendPayslips';

export type PayrollsProps = {
  organizationId: string | undefined;
  companyId: string | undefined;
};

const rpaGroupsConfig = {
  open: { color: 'primary', label: 'Abertos' },
  approved: {
    color: 'primary',
    label: 'Aprovados',
  },
  closed: { color: 'success', label: 'Fechados' },
};

type TypedPeriodSummary = PayrollPeriodSummary & {
  type: PayrollTypes;
};

type TypedPeriodList = Omit<PayrollPeriodList, 'data'> & {
  data: TypedPeriodSummary[];
};

type menuAction = {
  label: string;
  onClick: () => void;
  icon?: typeof SvgIcon;
  disabled?: boolean;
  tooltip?: string;
  divider?: boolean;
};

function RPAPage({ organizationId, companyId }: PayrollsProps) {
  const navigate = useNavigate();
  const [tab, setTab] = useState<keyof typeof rpaGroupsConfig>('open');
  const [countByStatus, setCountByStatus] = useState<{
    [key in keyof typeof rpaGroupsConfig]: number;
  }>({
    open: 0,
    approved: 0,
    closed: 0,
  });

  const [enableMoreActionsPopoverOpen, setEnableMoreActionsPopoverOpen] =
    useState(false);
  const [popoverAnchorEl, setPopOverAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const moreActionsState: {
    enableMoreActionsPopoverOpen: boolean;
    setEnableMoreActionsPopoverOpen: (value: boolean) => void;
    popoverAnchorEl: HTMLButtonElement | null;
    setPopOverAnchorEl: (value: HTMLButtonElement | null) => void;
  } = {
    enableMoreActionsPopoverOpen,
    setEnableMoreActionsPopoverOpen,
    popoverAnchorEl,
    setPopOverAnchorEl,
  };

  const filters = useFilters();
  const { filteringProps, searchProps, sortingProps, paginationProps } =
    useDataGrid({
      filters,
    });

  const useFetch: () => FetchResult<TypedPeriodList> = () => {
    const elementFilters = getActiveElementFilters(filteringProps);
    const rangeFilters = getActiveRangeFilters(filteringProps);
    return useQuery({
      queryKey: [
        organizationId,
        companyId,
        paginationProps,
        tab,
        elementFilters,
        rangeFilters,
      ],
      queryFn: () => {
        return fetchSearchAllPeriodsSummaries({
          pathParams: {
            organizationId: organizationId ?? '',
            companyId: companyId ?? '',
          },
          body: {
            pagination: {
              page: paginationProps.page,
              size: paginationProps.rowsPerPage,
            },
            sorting: {
              field: 'period',
              order: 'desc',
            },
            filtering: {
              elements: {
                status: [tab],
                active: ['true', ...(tab === 'closed' ? ['false'] : [])],
                type: ['rpa'],
                ...elementFilters,
              },
              ...(rangeFilters ? { ranges: rangeFilters } : {}),
            },
          },
        });
      },
      enabled: !!organizationId && !!companyId,
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        height: '100%',
      }}
    >
      <Toolbar />

      <Container>
        <Box pt={5}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            data-testid="payrolls-period-header"
            mb={5}
          >
            <RequestQuoteOutlined
              sx={{ height: '40px', width: '40px', marginRight: 1.5 }}
            />
            <Typography variant="h1">Pagamentos a autônomos</Typography>
          </Box>
          <DataGridToolbar
            filters={filters}
            searchProps={searchProps}
            filteringProps={filteringProps}
            searchPlaceholder="Procurar"
          >
            <Box
              sx={{
                alignContent: 'end',
                flex: 'end',
                display: 'flex',
                gap: 1,
                justifyContent: 'end',
                width: '100%',
              }}
            >
              <Button
                color={'primaryAlt'}
                sx={{
                  display: 'flex',
                  width: 160,
                  height: 40,
                  p: 1,
                  m: 0,
                }}
              >
                <Typography
                  variant={'body2'}
                  color="secondary"
                  fontWeight={400}
                >
                  Criar Pagamentos
                </Typography>
              </Button>
              <Button
                color="secondary"
                sx={{
                  display: 'flex',
                  width: 160,
                  height: 40,
                  p: 1,
                  m: 0,
                  gap: 1,
                }}
                onClick={(event: MouseEvent<HTMLButtonElement>) => {
                  moreActionsState.setPopOverAnchorEl(event.currentTarget);
                  moreActionsState.setEnableMoreActionsPopoverOpen(true);
                }}
              >
                <Typography
                  variant={'body2'}
                  color="primaryAlt"
                  fontWeight={400}
                >
                  Outras ações
                </Typography>
                <IconChevronDown height={'20px'} width={'20px'} />
              </Button>
            </Box>
            <EnableMoreActionsPopover
              moreActionsProps={moreActionsState}
              actions={[
                {
                  label: 'Repetir autônomos da última competência',
                  onClick: () => navigate(`/autonomos`),
                },
                {
                  label: 'Colar uma lista de matrículas',
                  onClick: () => navigate(`/autonomos`),
                },
                {
                  label: '',
                  onClick: undefined,
                  divider: true,
                },
                {
                  label: 'Remover todos autônomos',
                  onClick: () => navigate(`/autonomos`),
                  icon: TrashIcon(),
                  disabled: true,
                },
              ]}
            />
          </DataGridToolbar>
          <Box
            my={2}
            sx={(theme) => ({
              boxShadow: `0 -1px 0 ${theme.palette.strokes.light} inset`,
            })}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Tabs
              value={tab}
              onChange={(_, newTab) => setTab(newTab)}
              textColor="inherit"
              TabIndicatorProps={{
                sx: {
                  backgroundColor: `${rpaGroupsConfig[tab].color}.main`,
                },
              }}
              data-testid="rpa-period-tabs"
            >
              {Object.entries(rpaGroupsConfig).map(
                ([groupName, groupConfig]) => (
                  <Tab
                    key={groupName}
                    value={groupName}
                    icon={
                      <PeriodTabLabel
                        isSelected={groupName === tab}
                        config={groupConfig}
                        count={
                          countByStatus[
                            groupName as keyof typeof rpaGroupsConfig
                          ]
                        }
                      />
                    }
                    data-testid={`rpas-tab-${groupName}`}
                  />
                ),
              )}
            </Tabs>
          </Box>
          <DataFetching
            useHook={useFetch}
            Loading={() => (
              <Skeleton variant="rounded" height={400} width="100%" />
            )}
            onData={(data) => {
              if (data?.metadata?.countByStatus) {
                setCountByStatus({
                  ...{
                    open: 0,
                    approved: 0,
                    closed: 0,
                  },
                  ...data.metadata.countByStatus,
                });
              }
            }}
            Data={({ data: list }) => {
              return (
                <DataGrid
                  sortingProps={sortingProps}
                  paginationProps={paginationProps}
                  totalRowCount={list?.total ?? 0}
                  rows={list?.data ?? []}
                  getRowId={(row) => `${row.period}/${row.type}`}
                  onRowClick={(params) => {
                    navigate(
                      `/payrolls/${params.row.period}/${params.row.type}`,
                    );
                  }}
                  columns={getColumnsForStatus(tab)}
                  emptyMessage={getEmptyMessageForStatus(tab)}
                  getRowSx={() => ({
                    height: '60px',
                    ...getRowSxForStatus(tab),
                  })}
                />
              );
            }}
          />
        </Box>
      </Container>
    </Box>
  );
}

type PeriodTabLabelProps = {
  isSelected: boolean;
  config: (typeof rpaGroupsConfig)[keyof typeof rpaGroupsConfig];
  count: number;
};

function PeriodTabLabel({
  isSelected,
  config: { label, color },
  count,
}: PeriodTabLabelProps) {
  const fontWeight = isSelected ? 700 : 500;
  const textColor = isSelected ? `${color}.main` : 'text.secondary';
  const bgColor = isSelected ? `background.${color}` : 'background.default';
  return (
    <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
      <Typography color={textColor} variant="body1" fontWeight={fontWeight}>
        {label}
      </Typography>
      {count > 0 && (
        <Typography
          bgcolor={bgColor}
          color={textColor}
          py={0.25}
          px={1}
          borderRadius={2}
          variant="caption"
          fontWeight={fontWeight}
        >
          {count}
        </Typography>
      )}
    </Box>
  );
}

function useFilters(): FilterOptions {
  return [
    makeYearMonthPickerFilter({
      label: 'Competência',
      propertyToFilter: 'period',
    }),
    makeMoneyRangeFilter({
      label: 'Valor líquido',
      propertyToFilter: 'calculationTotals.netPay',
      getRangeMin: () => 0,
      getRangeMax: () => 1_000_000,
    }),
  ].filter(Boolean);
}

function getEmptyMessageForStatus(state: keyof typeof rpaGroupsConfig) {
  switch (state) {
    case 'open':
      return 'Não existem pagamentos em aberto.';
    case 'approved':
      return 'Não existem pagamentos aprovados.';
    case 'closed':
      return 'Não existem pagamentos fechados.';
    default:
      return 'Não há pagamentos.';
  }
}

function getRowSxForStatus(state: keyof typeof rpaGroupsConfig) {
  switch (state) {
    case 'approved':
    case 'closed':
      return {
        'td:nth-last-of-type(1)': {
          '*': {
            opacity: 0,
          },
        },
        ':hover': {
          'td:nth-last-of-type(1)': {
            '*': {
              opacity: 1,
            },
          },
        },
      };
    default:
      return {};
  }
}

const columns: GridColDef<TypedPeriodSummary>[] = [
  {
    field: 'period',
    headerName: 'Competência',
    sortable: true,
    valueGetter: (params: GridValueGetterParams) => {
      return formatPeriodDate(params.row.period);
    },
  },
  {
    field: 'type',
    headerName: 'Tipo de folha',
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      switch (params.row.type) {
        case 'monthly':
          return 'Mensal';
        case 'thirteenthFirst':
          return '13o Salário (1/2)';
        case 'thirteenthSecond':
          return '13o Salário (2/2)';
        case 'advance':
          return 'Adiantamento';
        case 'complementary':
          return 'Complementar';
        default:
          return params.row.type;
      }
    },
  },
  {
    field: 'counts',
    headerName: 'Colaboradores',
    valueGetter: (params) => {
      const payrollsCount = {
        open: countPayrollStatus(
          params.row.totalizers,
          (statusIndex) => statusIndex <= payrollStatusIndex.open,
        ),
        approved: countPayrollStatus(
          params.row.totalizers,
          (statusIndex) =>
            statusIndex > payrollStatusIndex.open &&
            statusIndex <= payrollStatusIndex.approved,
        ),
        closed: countPayrollStatus(
          params.row.totalizers,
          (statusIndex) =>
            statusIndex > payrollStatusIndex.approved &&
            statusIndex < payrollStatusIndex.archived,
        ),
        archived: countPayrollStatus(
          params.row.totalizers,
          (statusIndex) => statusIndex === payrollStatusIndex.archived,
        ),
      }[params.row.status];

      return formatInteger(payrollsCount, {
        minimumIntegerDigits: 2,
      });
    },
  },
  {
    field: 'netPay',
    headerName: 'Valor líquido',
    sortable: false,
    valueGetter: (params) =>
      formatMoney(params.row.totalizers.totals.netPay) || '--',
  },
  FLAGS.ENABLE_PAYROLL_TOTAL_COST &&
    FLAGS.ENABLE_PAYROLL_TOTAL_COST_PROVISIONS && {
      field: 'totalCost',
      headerName: 'Custo total',
      sortable: false,
      valueGetter: (params) =>
        formatMoney(params.row.totalizers.totals.totalCost) || '--',
    },
];

const commonColumns = columns.filter(Boolean);

function getColumnsForStatus(state: keyof typeof rpaGroupsConfig) {
  switch (state) {
    case 'open':
      return [
        ...commonColumns,
        {
          field: 'approvalDate',
          headerName: 'Aprovar até',
          valueGetter: () => {
            return '--/-- às --:--';
          },
        },
        {
          field: 'reviewCall',
          headerName: '',
          renderCell: () => {
            return (
              <Box display="flex" justifyContent="center">
                <Typography
                  variant="body2"
                  color="info.main"
                  px={2}
                  fontWeight={700}
                >
                  Revisar
                </Typography>
              </Box>
            );
          },
          maxWidth: 10,
        },
      ];
    case 'approved':
    case 'closed':
      return [
        ...commonColumns,
        {
          field: 'receipts',
          headerName: 'Recibos',
          width: 220,
          renderCell: (
            params: GridRenderCellParams<
              string | undefined,
              TypedPeriodSummary
            >,
          ) => {
            const { sendPayslipsProps, sendPayslips, SendPayslipsComponent } =
              // The linter is whining about this not being a React component because it doesn't
              // start with a capital letter!
              // eslint-disable-next-line react-hooks/rules-of-hooks
              useSendPayslips({
                organizationId: params.row.organizationId,
                companyId: params.row.companyId,
              });

            const hasAlreadySentAll =
              params.row.totalizers.counts.payslips.byStatus[
                payslipStatuses.approved
              ] === 0;

            if (hasAlreadySentAll) {
              return (
                <Box display="flex" alignItems="center" gap={1}>
                  <CircleIcon
                    sx={{
                      width: '8px',
                      color: 'info.main',
                    }}
                  />
                  <Typography
                    variant="body2"
                    color="info.main"
                    fontWeight={700}
                  >
                    Enviados
                  </Typography>
                </Box>
              );
            }

            const button = (
              <Button
                disabled={
                  !hasGeneratedAllPayslips(params.row.totalizers) ||
                  state === 'closed'
                }
                color="secondary"
                startIcon={<Send />}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  sendPayslips({
                    periodId: params.row.period,
                    payrollType: params.row.type,
                  });
                }}
              >
                Enviar
              </Button>
            );

            return (
              <Box display="flex" alignItems="center">
                <CircleIcon
                  sx={(theme) => ({
                    width: '8px',
                    color: theme.palette.warning.main,
                  })}
                />
                <Box pl={1} pr={2}>
                  <Typography
                    variant="body2"
                    color="warning.main"
                    fontWeight={700}
                    whiteSpace="nowrap"
                  >
                    Enviar até --/--
                  </Typography>
                </Box>
                {hasGeneratedAllPayslips ? (
                  button
                ) : (
                  <Tooltip title="Os recibos ainda estão sendo gerados. Tente novamente dentro de alguns instantes.">
                    <span>{button}</span>
                  </Tooltip>
                )}
                <SendPayslipsComponent {...sendPayslipsProps} />
              </Box>
            );
          },
        },
        {
          field: 'actions',
          headerName: '',
          renderCell: () => <PeriodActionsMenu />,
          width: 48,
        },
      ];
    default:
      return commonColumns;
  }
}

function PeriodActionsMenu() {
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);
  return (
    <Box display="flex" justifyContent="flex-end">
      <IconButton
        size="small"
        onClick={(event) => {
          setOpen(true);
          event.stopPropagation();
        }}
        ref={menuRef}
        sx={{
          borderRadius: '8px',
          padding: '4px',
        }}
      >
        <MoreVertIcon
          fontSize="inherit"
          sx={{
            width: '24px',
            height: '24px',
          }}
        />
      </IconButton>
      <Popover
        open={open}
        anchorEl={menuRef.current}
        onClick={(event) => event.stopPropagation()}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
        sx={{
          m: 1,
        }}
        data-testid="filters-popover"
      >
        <Button
          color="secondary"
          size="large"
          sx={{
            border: 'none',
          }}
        >
          Baixar Extrato
        </Button>
      </Popover>
    </Box>
  );
}

function EnableMoreActionsPopover({
  moreActionsProps,
  actions,
}: {
  moreActionsProps: {
    enableMoreActionsPopoverOpen: boolean;
    setEnableMoreActionsPopoverOpen: (value: boolean) => void;
    popoverAnchorEl: HTMLButtonElement | null;
    setPopOverAnchorEl: (value: HTMLButtonElement | null) => void;
  };
  actions: menuAction[];
}) {
  return (
    <Popover
      open={moreActionsProps.enableMoreActionsPopoverOpen}
      anchorEl={moreActionsProps.popoverAnchorEl}
      onClose={() => moreActionsProps.setEnableMoreActionsPopoverOpen(false)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      elevation={1}
      sx={{
        mt: 1,
      }}
      data-testid="filters-popover"
    >
      <List sx={{ width: '346px', pt: 0.5 }}>
        {actions.map(({ label, onClick, icon, divider, disabled }) =>
          divider ? (
            <Divider />
          ) : (
            <ListItemButton
              sx={{ width: '100%', gap: 1.5 }}
              key={label}
              onClick={(event) => {
                event.stopPropagation();
                onClick();
                moreActionsProps.setEnableMoreActionsPopoverOpen(false);
              }}
            >
              {/* {icon ? <ListItemIcon sx={{ minWidth: '30px' }}>
                <img src={icon} width={'20px'} height={'20px'} alt={'List Icon'} />
              </ListItemIcon> : undefined
              } */}
              {icon ? (
                <Box
                  component={icon}
                  sx={{
                    p: 0,
                    height: '20px',
                    width: '20px',
                    color: disabled ? '#BABABF' : 'primary',
                  }}
                />
              ) : undefined}

              {/* <ListItemText primary={label} sx={{ width: '100%', color: disabled ? 'red' : 'text.secondary' }} /> */}
              <Typography
                sx={(theme) => ({
                  width: '100%',
                  color: disabled
                    ? theme.palette.strokes.heavy
                    : theme.palette.text.primary,
                })}
              >
                {label}
              </Typography>
            </ListItemButton>
          ),
        )}
      </List>
    </Popover>
  );
}

export default RPAPage;
